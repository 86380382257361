import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  themeV2 as theme,
  ThemeProvider,
  LocalizationProvider,
} from '@valerahealth/ui-components'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from 'store/index'
import Router from './routes'

// product is going back and forth on what variant to use, this is here for reference if we need to switch
// const theme = createTheme(
//   {
//   ...themeOptions,
//   components: {
//     ...themeOptions.components,
//     MuiTextField: {
//       ...themeOptions.components?.MuiTextField,
//       defaultProps: {
//         ...themeOptions.components?.MuiTextField?.defaultProps,
//         variant: 'standard',
//       },
//     },
//   },
// }
// )

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>...loading</div>} persistor={persistor}>
        <BrowserRouter>
          <LocalizationProvider>
            <ThemeProvider theme={theme}>
              <Router />
            </ThemeProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}
