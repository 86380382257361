/** most of the translations are shared with the patient onboarding form but a few are changed */

import { FORMATTED_CALL_CENTER_PHONE } from 'constant'

// Define overrides and net new values
export default {
  PatientOnboarding: {
    startTitle: 'Refer a Patient',
    startDescription:
      "You are requesting a patient onboarding appointment with Valera Health. Please be prepared to provide your patient's contact information, discharge documents (if applicable), and treatment history. <br/> If you are requesting a consultation for yourself, please <Link>click here</Link>. <br/> At this time, we are only able to serve patients in these following states: <b>CO*, MA, NJ, NY, VT, WA</b>.",
    referrerInformationTitle: 'Referrer Information',
    referralSource: '$t(referrer) Organization',
    hospitalized:
      'Is this a referral for a patient who has been psychiatrically hospitalized or admitted to the ED for psychiatric reasons within the past 30 days?',
    reasonForAdmission: 'Reason for Admission',
    dischargeDiagnosis: 'Discharge Diagnosis',
    dischargeMedications: 'Discharge Medications',
    conditionInterferingWithVirtualTreatment:
      'Does the patient have a condition or circumstance, such as cognitive impairment or conservatorship, that may interfere with their ability to independently participate in virtual treatment?',
    treatmentHistory: 'Relevant Treatment History',
    dischargeType: 'Type of Discharge',
    dischargeDate: 'Date of Discharge',
    dischargeSummary:
      'If applicable, please include either an admission note or a discharge summary.',
    additionalDocuments: 'Upload Additional Patient Documentation',
    selfPay: 'Patient will not use insurance',
    selfPayTerms:
      "You acknowledge and agree that all billable services will be rendered to this patient as self-pay (out of pocket), including any applicable no-show fees. If the patient is able to provide insurance coverage information, we will verify benefits and attempt to bill for eligible services if we are in the plan's network.",
    finalizeTitle: 'Patient Availability',
    finalizeDescription:
      'Please select the time slot(s) that your patient is most likely available and our team will give them a call at one of those times.',
    finalizeTOCNotice:
      'This patient may qualify for our Transitions of Care Psychiatric Hospitalization / Emergency Department Follow Up program.',
    finalizeTOCDescription:
      'Please select all available days and times for provider outreach.',
    finalizeReferrerDisclaimer:
      'By clicking submit, you acknowledge that you are a healthcare provider or from a healthcare provider hospital or health insurance plan.',
    endTitle: 'Thank you for completing your patient referral!',
    endDescriptionNonToc: `A member of our Health Connector team will outreach the patient within 3 business days to verify their information and match them with an available provider.<br/>If you have any questions or don’t hear from a member of our team in that time frame, please email us at <email>support@valerahealth.com</email>, or call us at <phone>${FORMATTED_CALL_CENTER_PHONE}</phone> and choose the new patient option to speak with a Health Connector.`,
    endDescriptionToc:
      "A dedicated provider will outreach the patient on the selected day and time window.<br/> If you have any questions to don't hear from a member of our team in that timeframe, please email us at <email>pathways@valerahealth.com</email>",
    referAnotherPatientLink: 'Refer Another Patient',
    identificationHlpTxt:
      'Please upload a government-issued ID. This is optional and can be added by the patient when matched to Valera services.',
    invalidCaptchaToken:
      'There was an error validating Google Captcha, please try again or contact support.',
  },
  uploadFile: 'Upload File',
  referrer: 'Referrer',
  referrerFirstName: '$t(referrer) $t(firstName)',
  referrerLastName: '$t(referrer) $t(lastName)',
  referrerPhone: '$t(referrer) $t(phone)',
  referrerEmailAddress: '$t(referrer) $t(email)',
  hasSecondaryInsurance: 'Does the patient have any other forms of insurance?',
}
